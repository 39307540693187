import { BatalhaoRegiao } from '.';

export class Batalhao implements Shared.IEntity{

    id!: string;
    nome: string = '';
    regioes: BatalhaoRegiao [] = [];

    constructor(model?: Batalhao){

        if(!model)
            return;
            
        this.id = model.id;
        this.nome = model.nome;
        this.regioes = model.regioes;
    }

}