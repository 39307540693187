export class Empresa implements Shared.IEntity{

    id!: string;
    nome: string = '';

    constructor(model?: Empresa){

        if(!model)
            return;
            
        this.id = model.id;
        this.nome = model.nome;
    }

}