import {http, httpHeader} from '@/core/ApiConfig';
import { Service } from '../shared';

export class VeiculoService extends Service{

  constructor(){
    super('veiculo');
  }

  MontaFiltro(filter: any){
      
    let paramTemp = '';

    if (filter.placa){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `Contains(toupper(placa), toupper('${filter.placa}'))`;
    }

    if (filter.chassi){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `Contains(toupper(chassi), toupper('${filter.chassi}'))`;
    }

    if (filter.empresaId){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `empresaId eq ${filter.empresaId}`;
    }

    if (filter.situacaoId){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `situacaoId eq ${filter.situacaoId}`;
    }

    return paramTemp;
  
  }

  public UltimoHistorico(placa: string, chassi: string) {
    return http.get(`${this.GetNomeControle()}/UltimoHistorico`, {
      params: {
        placa: placa,
        chassi: chassi
      },
      headers: httpHeader.headers
    });
  }

  ObterHistorico(placa: string, chassi: string) {
    return http.get(`${this.GetNomeControle()}/Historico`, {
        params: {
            placa: placa,
            chassi: chassi
        },
        headers: httpHeader.headers
    });
  }
  
}