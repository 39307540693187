import { SituacaoVeiculo } from './SituacaoVeiculo';
import { Empresa } from './Empresa';
export class Veiculo implements Shared.IEntity{

    id!: string;
    placa: string = '';
    chassi: string = '';
    empresaId: number = 0;
    empresa!: Empresa;
    premio: number = 0;
    telefone: string = '';
    situacaoId: number = 0;
    situacao!: SituacaoVeiculo;
    marca: string = '';
    modelo: string = '';
    cor: string = '';
    fipe: number = 0;
    observacao: string = '';

    constructor(model?: Veiculo){

        if(!model)
            return;

        this.id = model.id;
        this.placa = model.placa;
        this.chassi = model.chassi;
        this.empresaId = model.empresaId;
        this.premio = model.premio;
        this.telefone = model.telefone;
        this.situacaoId = model.situacaoId;
        this.marca = model.marca;
        this.modelo = model.modelo;
        this.cor = model.cor;
        this.fipe = model.fipe;
        this.observacao = model.observacao;
    }
}