
























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Veiculo } from "@/core/models/geral";
import { VeiculoService } from "@/core/services/geral";

@Component
export default class CadastroUsuario extends Vue {
  @Prop() private item!: Veiculo;
  @Prop() private value!: string;

  itemOriginal!: Veiculo;
  service = new VeiculoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  $refs!: {
    form: HTMLFormElement
  }

  veiculos: Veiculo[] = []

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
      return;
    }

    const service = new VeiculoService();
    service.ObterHistorico(this.item.placa, this.item.chassi).then(
      res=>{
        this.veiculos = res.data;
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    );

  }

  beforeUpdate(){

    if (!this.dialog){
      this.$emit('fechou');
    }

  }


  mounted() {

  }

  Close() {
    this.dialog = false;
  }
}
