import Vue from 'vue'

import MasterPage           from "./shared/MasterPage.vue";
import InformacaoCard       from "./shared/InformacaoCard.vue"

import CadastroBatalhao     from "./geral/batalhao/Cadastro.vue"
import CadastroEmpresa      from "./geral/empresa/Cadastro.vue"
import CadastroUsuario      from "./geral/usuario/Cadastro.vue"
import CadastroVeiculo      from "./geral/veiculo/Cadastro.vue"
import HistoricoVeiculo     from "./geral/veiculo/Historico.vue"

import RoubadoCard   from "../components/geral/roubados/RoubadoCard.vue"

Vue.component("master-page", MasterPage);
Vue.component("informacao-card", InformacaoCard);

Vue.component("cadastro-batalhao", CadastroBatalhao);
Vue.component("cadastro-empresa", CadastroEmpresa);
Vue.component("cadastro-usuario", CadastroUsuario);
Vue.component("cadastro-veiculo", CadastroVeiculo);
Vue.component("historico-veiculo", HistoricoVeiculo);

Vue.component("roubado-card", RoubadoCard);