import { Batalhao } from './Batalhao';

export class BatalhaoRegiao implements Shared.IEntity{

    id!: string;
    nome: string = '';
    batalhaoId!: string;
    batalhao!: Batalhao;
    excluido: boolean = false;
    
    constructor(model?: BatalhaoRegiao){

        if(!model)
            return;
            
        this.id = model.id;
        this.batalhaoId = model.batalhaoId;
        this.nome = model.nome;
    }

}