import { BatalhaoRegiao, OperacaoHistorico, SituacaoVeiculo, Usuario } from '.';

export class VeiculoHistorico implements Shared.IEntity{

    id!: string;
    placa: string = '';
    chassi: string = '';
    usuarioId: string = '';
    usuario!: Usuario;
    situacaoId: string = '';
    situacao!: SituacaoVeiculo;
    operacaoId: string = '';
    operacao!: OperacaoHistorico;
    localizacaoLink: string = '';
    longitude: string = '';
    latitude: string = '';
    localRouboId!: string;
    localRoubo!: BatalhaoRegiao;

    constructor(model?: VeiculoHistorico){

        if(!model)
            return;

        this.id = model.id;
        this.placa = model.placa;
        this.chassi = model.chassi;
        this.usuarioId = model.usuarioId;
        this.situacaoId = model.situacaoId;
        this.operacaoId = model.operacaoId;
        this.localizacaoLink = model.localizacaoLink;
        this.longitude = model.longitude;
        this.latitude = model.latitude;
        this.localRouboId = model.localRouboId;
    }
}