import { Perfil } from ".";
import { Contato } from "../shared";

export class Usuario implements Shared.IEntity{

    id!: string;
    login: string = '';
    senha: string = '';
    complementar: Contato = new Contato();
    foto: any = null;
    ativo: boolean = true;
    temaEscuro: boolean = false;
    perfilId!: string;
    perfil!: Perfil;

    constructor(model?: Usuario){

        if(!model)
            return;

        this.id = model.id;
        this.login = model.login;
        this.senha = model.senha;
        this.complementar = model.complementar;
        this.foto = model.foto;
        this.ativo = model.ativo;
        this.temaEscuro = model.temaEscuro;
        this.perfilId = model.perfilId;
        this.perfil = model.perfil;
    }
}