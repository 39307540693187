import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect:'/home'},
  { path: '/home', name: 'home', component: () => import("../views/Home.vue")},
  { path: '/login', name: 'login', component: () => import("../views/Login.vue")},
  { path: '/batalhao', name: 'batalhao', component: () => import("../views/geral/batalhao/Lista.vue")},
  { path: '/empresa', name: 'empresa', component: () => import("../views/geral/empresa/Lista.vue")},
  { path: '/veiculo', name: 'veiculo', component: () => import("../views/geral/veiculo/Lista.vue")},
  { path: '/historico', name: 'veiculohistorico', component: () => import("../views/geral/veiculo/Historico.vue")},
  { path: '/usuario', name: 'usuario', component: () => import("../views/geral/usuario/Lista.vue")},
  { path: '/importacao', name: 'importacao', component: () => import("../views/geral/importacao/Lista.vue")}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
