var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.Close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Cadastro Batalhão")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":"","disabled":!_vm.valid},on:{"click":function($event){return _vm.Salvar()}}},[_vm._v("Salvar")])],1)],1),_c('v-card-text',{staticClass:"mt-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","rules":_vm.fieldRules,"dense":"","counter":60,"maxlength":"60","outlined":""},model:{value:(_vm.item.nome),callback:function ($$v) {_vm.$set(_vm.item, "nome", $$v)},expression:"item.nome"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerRegiao,"items":_vm.item.regioes,"items-per-page":-1,"item-class":_vm.itemExcluido,"sort-by":['nome'],"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"fab":"","small":"","depressed":"","color":"primary","dark":""},on:{"click":function($event){return _vm.AbrirDialogRegiao()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nova Região")])])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","disabled":item.excluido},on:{"click":function($event){return _vm.AbrirDialogRegiao(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","disabled":item.excluido},on:{"click":function($event){return _vm.ExcluirRegiao(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":"80vw"},model:{value:(_vm.dialogRegiaoCadastro),callback:function ($$v) {_vm.dialogRegiaoCadastro=$$v},expression:"dialogRegiaoCadastro"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"white"}},[_vm._v("mdi-map")]),_vm._v(" Região ")],1),_c('v-card-text',{staticClass:"mt-2"},[_c('v-text-field',{attrs:{"label":"Região","counter":60,"maxlength":"60","dense":"","outlined":""},model:{value:(_vm.batalhaoRegiao.nome),callback:function ($$v) {_vm.$set(_vm.batalhaoRegiao, "nome", $$v)},expression:"batalhaoRegiao.nome"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secundary","text":""},on:{"click":function($event){_vm.dialogRegiaoCadastro = false}}},[_vm._v("Fechar")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.validRegiao},on:{"click":function($event){return _vm.SalvarRegiao()}}},[_vm._v("Salvar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }