











































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { Batalhao, BatalhaoRegiao } from "@/core/models/geral";
import { BatalhaoService } from "@/core/services/geral";

@Component
export default class CadastroEmpresa extends Vue {
  @Prop() private item!: Batalhao;
  @Prop() private value!: string;

  observer! : jsonpatch.Observer<Batalhao>;

  service = new BatalhaoService();
  valid = true;
  validRegiao = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement,
    formRegiao: HTMLFormElement
  }
  headerRegiao: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Nome', value: 'nome' },
  ];
  dialogRegiaoCadastro: boolean = false;
  batalhaoRegiao = new BatalhaoRegiao();
  editarBatalhaoRegiao: boolean = false;

  itemExcluido(item: any){
    return item.excluido ? 'itemExcluido' : '';
  }

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
    if (this.$refs.formRegiao) {
      this.$refs.formRegiao.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else{
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {

  }

  AbrirDialogRegiao(item?: BatalhaoRegiao){
    if(item){
      this.batalhaoRegiao = item;
      this.editarBatalhaoRegiao = true;
    }
    else{
      this.editarBatalhaoRegiao = false;
      this.batalhaoRegiao = new BatalhaoRegiao();
      this.batalhaoRegiao.batalhaoId = this.item.id;
    }
    this.dialogRegiaoCadastro = true;
  }

  SalvarRegiao(){

    if(!this.editarBatalhaoRegiao){
      this.item.regioes.push(this.batalhaoRegiao)
    }
    this.batalhaoRegiao = new BatalhaoRegiao();
    this.dialogRegiaoCadastro = false;
  }

  ExcluirRegiao(item: BatalhaoRegiao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const index = this.item.regioes.indexOf(item);
        // this.item.regioes.splice(index, 1);
        item.excluido = true;  
        delete this.item.regioes[index];
        
        this.dialog = false;
        this.dialog = true;

      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  Salvar() {
    if (this.$refs.form.validate()) {

      let pacthModel = jsonpatch.generate(this.observer);

      (this.item.id ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
