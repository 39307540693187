import { http } from '@/core/ApiConfig';
import { Service } from '../shared/';

export class VeiculoHistoricoService extends Service{

  constructor(){
    super('veiculohistorico');
  }

  MontaFiltro(filter: any){
      
    let paramTemp = '';

    if (filter.placa){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `Contains(toupper(placa), toupper('${filter.placa}'))`;
    }

    if (filter.chassi){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `Contains(toupper(chassi), toupper('${filter.chassi}'))`;
    }

    if (filter.situacao){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `situacaoVeiculoId eq ${filter.situacao}`;
    }

    if (filter.operacao){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `operacaoId eq ${filter.operacao}`;
    }

    if (filter.localRoubo){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `localRouboId eq ${filter.localRoubo}`;
    }

    return paramTemp;
  
  }

  public AtualizarLocalRoubo(id: string, regiaoId: string) {
    return http.put(`${this.GetNomeControle()}/${id}/localRoubo`, null, {
      params: {
        regiaoId
      }
    });
  }

  public RelatorioROubados(empresaId: string, dataInicial: string, dataFinal: string) {
    return http.get(`${this.GetNomeControle()}/relatorioroubados`, {
      params: {
        empresaId,
        dataInicial,
        dataFinal
      }
    });
  }
    
}