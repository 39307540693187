






















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Usuario } from "@/core/models/geral";
import { PerfilService, UsuarioService } from "@/core/services/geral";
import { ArquivoService } from "@/core/services/shared";

@Component
export default class CadastroUsuario extends Vue {
  @Prop() private item!: Usuario;
  @Prop() private value!: string;

  itemOriginal!: Usuario;

  service = new UsuarioService();
  perfilService = new PerfilService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  $refs!: {
    form: HTMLFormElement
  };
  perfis: [] = [];

  foto: any = null;

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  RemoveImage(){
    this.item.foto = null;
    this.foto = null;
  }

  async LoadImage(){

    if (!this.foto)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.foto);
    this.item.foto = dados.replace(/^[^,]*,/, "");
  }

  mounted(){
    this.perfilService.ListarTudo().then(
      res => {
        this.perfis = res.data.items;
      },
      err => this.$swal('Aviso', err.response.data, 'warning')
    )
  }

  Salvar() {
    if (this.$refs.form.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
